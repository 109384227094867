<template>
  <div
    class="osk-header-bottom"
    @mouseleave="onBlur"
  >
    <div class="osk-header-bottom__top">
      <div class="osk-wrapper">
        <div
          :class="{
            'osk-header-bottom__links': true,
            'osk-header-bottom__links_w-auto': categories.length < 7,
          }"
        >
          <nuxt-link
            v-for="(category, index) in categories"
            :key="`${category.href}-${index}`"
            :to="category.href"
            no-prefetch
            :class="{
              'osk-header-bottom__link': true,
              'osk-header-bottom__link_active': index + 1 === hoveredItemIdx,
            }"
            :data-text="category.title"
            @mouseenter="onHover(index, category)"
            @click="onBlur($event, category)"
          >
            {{ category.title }}
          </nuxt-link>
        </div>
      </div>
    </div>

    <transition name="fade">
      <div
        v-if="isHovered"
        class="osk-header-bottom__bottom"
      >
        <div
          :class="{
            'osk-wrapper': true,
            'osk-wrapper_space-between': subcategories.length === 5,
          }"
        >
          <div
            v-for="(subcategory, index) in subcategories"
            :key="`${subcategory.title}-subcategory-${index}`"
            class="osk-header-bottom__bottom-col"
          >
            <div
              v-if="subcategory.title"
              class="osk-header-bottom__bottom-title"
            >
              {{ subcategory.title }}
            </div>

            <div
              v-if="subcategory.items?.length"
              :class="{
                'osk-header-bottom__bottom-list': true,
                'osk-header-bottom__bottom-list_no-title': !subcategory.title,
              }"
            >
              <nuxt-link
                v-for="(item, idx) in subcategory.items"
                :key="`${item.title}-item-${idx}`"
                :to="item.href"
                no-prefetch
                class="osk-header-bottom__bottom-link"
                @click="onBlur($event, item)"
              >
                {{ item.title }}
              </nuxt-link>
            </div>

            <nuxt-link
              v-if="subcategory.href"
              :to="subcategory.href"
              no-prefetch
              class="osk-header-bottom__bottom-all"
              @click="onBlur"
            >
              {{ $t('components.header.headerBottom.all') }}
            </nuxt-link>
          </div>

          <HeaderBanner
            v-if="subcategories.length < 5"
            :banner="getActiveBanner()"
            class="osk-header-bottom__bottom-banner"
            @click="onBlur"
          />
        </div>
      </div>
    </transition>

    <teleport to="body">
      <transition name="fade">
        <div
          v-if="isHovered"
          class="osk-header-bottom__overlay"
        />
      </transition>
    </teleport>
  </div>
</template>

<script lang="ts" setup>
import type { ICategoryMenu } from '~/types/menu';

import { AnalyticsEvents } from '~/types/analytics';

let timer: ReturnType<typeof setTimeout> | undefined;

const props = defineProps<{
  categories: ICategoryMenu[]
}>();

const { $addEvent } = useNuxtApp();

const { activeMenuCategory } = useActiveMenuCategory();
const { getActiveBanner } = useHeaderBanner(activeMenuCategory);

const isHovered = ref(false);
const hoveredItemIdx = ref(0);

const subcategories = computed<ICategoryMenu[]>(() => {
  if (!hoveredItemIdx.value) return [];

  const data = props.categories[hoveredItemIdx.value - 1];

  if (!data.items) return [];

  return data.items;
});

function onHover(index: number, category: ICategoryMenu) {
  if (!category.items?.length) {
    onBlur();
    return;
  }

  if (isHovered.value) {
    hoveredItemIdx.value = index + 1;
    return;
  }

  timer = setTimeout(() => {
    hoveredItemIdx.value = index + 1;
    isHovered.value = true;
  }, 150);
}

function onBlur(e?: Event, category?: ICategoryMenu) {
  if (timer) {
    clearTimeout(timer);
    timer = undefined;
  }

  hoveredItemIdx.value = 0;
  isHovered.value = false;

  if (category) {
    $addEvent(AnalyticsEvents.MenuLinkClick, {
      item: category.title,
    });
  }
}
</script>

<style scoped lang="scss">
@import "~/assets/scss/settings/index";

.osk-header-bottom {
  &__top {
    padding-bottom: $indent-large;
    padding-top: $indent-medium;

    .osk-wrapper {
      padding: 0 14px;
    }
  }

  &__links {
    display: flex;
    align-items: center;
    justify-content: space-between;

    &_w-auto {
      justify-content: center;

      .osk-header-bottom__link {
        margin: 0 29px;
      }
    }
  }

  &__link {
    @include active-bold;
  }

  &__bottom {
    border-top: 1px solid $grey-3;
    padding-top: $indent-mlarge;
    padding-bottom: 62px;
    position: absolute;
    background: #fff;
    width: 100%;

    .osk-wrapper {
      display: flex;

      &_space-between {
        justify-content: space-between;

        .osk-header-bottom__bottom-col {
          margin-right: 0;
        }
      }
    }
  }

  &__bottom-col {
    width: 191px;
    margin-right: 60px;
  }

  &__bottom-title {
    font-weight: $font-weight-bold;
    margin-bottom: $indent-large;
  }

  &__bottom-list {
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    &_no-title {
      margin-top: 41px;
    }
  }

  &__bottom-link {
    margin-bottom: $indent-mmedium;

    &:hover {
      color: $grey;
    }
  }

  &__bottom-all {
    font-weight: $font-weight-semibold;
    text-decoration: underline;

    &:hover {
      text-decoration: none;
    }
  }

  &__bottom-banner {
    margin-left: auto;
  }

  &__overlay {
    position: fixed;
    z-index: 9;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    background: rgba(0,0,0,.6);
    overflow: hidden;
  }
}
</style>
