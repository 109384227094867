import { ICategoryMenu } from '~/types/menu';

export const menEN: ICategoryMenu[] = [
  {
    title: 'OSKELLY Favorites',
    href: '/catalog/men?oskellyChoice=true',
    items: [
      {
        title: 'Accessories',
        href: '/catalog/men/accessories?oskellyChoice=true',
        items: [
          {
            title: 'Hats',
            href: '/catalog/men/accessories/hats?oskellyChoice=true',
          },
          {
            title: 'Wallets and Cardholders',
            href: '/catalog/men/accessories/wallets-and-cardholders?oskellyChoice=true',
          },
          {
            title: 'Glasses',
            href: '/catalog/men/accessories/glasses?oskellyChoice=true',
          },
          {
            title: 'Belts',
            href: '/catalog/men/accessories/belts?oskellyChoice=true',
          },
          {
            title: 'Jewelry',
            href: '/catalog/men/accessories/jewelry?oskellyChoice=true',
          },
          {
            title: 'Watches',
            href: '/catalog/men/accessories/watches?oskellyChoice=true',
          },
        ],
      },
      {
        title: 'Beauty',
        href: '/catalog/men/beauty?oskellyChoice=true',
      },
      {
        title: 'Shoes',
        href: '/catalog/men/shoes?oskellyChoice=true',
        items: [
          {
            title: 'Boots',
            href: '/catalog/men/shoes/boots?oskellyChoice=true',
          },
          {
            title: 'Sneakers',
            href: '/catalog/men/shoes/sneakers?oskellyChoice=true',
          },
          {
            title: 'Loafers and Moccasins',
            href: '/catalog/men/shoes/loafers-and-moccasins?oskellyChoice=true',
          },
          {
            title: 'Sandals and Flip flops',
            href: '/catalog/men/shoes/sandals-and-flip-flops?oskellyChoice=true',
          },
          {
            title: 'Flats',
            href: '/catalog/men/shoes/flats?oskellyChoice=true',
          },
        ],
      },
      {
        title: 'Clothing',
        href: '/catalog/men/clothes?oskellyChoice=true',
        items: [
          {
            title: 'Trousers and Shorts',
            href: '/catalog/men/clothes/trousers-and-shorts?oskellyChoice=true',
          },
          {
            title: 'Outerwear',
            href: '/catalog/men/clothes/outerwear?oskellyChoice=true',
          },
          {
            title: 'Suits',
            href: '/catalog/men/clothes/suits?oskellyChoice=true',
          },
          {
            title: 'T-shirts and Singlets',
            href: '/catalog/men/clothes/t-shirts-and-singlets?oskellyChoice=true',
          },
          {
            title: 'Jackets',
            href: '/catalog/men/clothes/jackets?oskellyChoice=true',
          },
          {
            title: 'Shirts',
            href: '/catalog/men/clothes/shirts?oskellyChoice=true',
          },
          {
            title: 'Hoodies and Sweatshirts',
            href: '/catalog/men/clothes/hoodies-and-sweatshirts?oskellyChoice=true',
          },
        ],
      },
      {
        title: 'Bags',
        href: '/catalog/men/bags?oskellyChoice=true',
        items: [
          {
            title: 'Briefcases',
            href: '/catalog/men/bags/briefcases?oskellyChoice=true',
          },
          {
            title: 'Shoulder Bags',
            href: '/catalog/men/bags/shoulder-bags?oskellyChoice=true',
          },
        ],
      },
    ],
  },
  {
    title: 'New In',
    href: '/catalog/men',
    items: [
      {
        title: 'Accessories',
        href: '/catalog/men/accessories',
        items: [
          {
            title: 'Smartphone Accessories',
            href: '/catalog/men/accessories/smartphone-accessories',
          },
          {
            title: 'Keyrings',
            href: '/catalog/men/accessories/keyrings',
          },
          {
            title: 'Business Card Holders',
            href: '/catalog/men/accessories/business-card-holders',
          },
          {
            title: 'Ties and Bow ties',
            href: '/catalog/men/accessories/ties-and-bow-ties',
          },
          {
            title: 'Hats',
            href: '/catalog/men/accessories/hats',
          },
          {
            title: 'Key Cases',
            href: '/catalog/men/accessories/housekeepers',
          },
          {
            title: 'Wallets and Cardholders',
            href: '/catalog/men/accessories/wallets-and-cardholders',
          },
        ],
      },
      {
        title: 'Beauty',
        href: '/catalog/men/beauty',
        items: [
          {
            title: 'Accessories',
            href: '/catalog/men/beauty/accessories',
          },
          {
            title: 'Barbershop',
            href: '/catalog/men/beauty/barbershop',
          },
          {
            title: 'Deodorants',
            href: '/catalog/men/beauty/deodorants',
          },
          {
            title: 'Fragrance',
            href: '/catalog/men/beauty/fragrance',
          },
        ],
      },
      {
        title: 'Shoes',
        href: '/catalog/men/shoes',
        items: [
          {
            title: 'Boots',
            href: '/catalog/men/shoes/boots',
          },
          {
            title: 'Sneakers',
            href: '/catalog/men/shoes/sneakers',
          },
          {
            title: 'Loafers and Moccasins',
            href: '/catalog/men/shoes/loafers-and-moccasins',
          },
          {
            title: 'Sandals and Flip flops',
            href: '/catalog/men/shoes/sandals-and-flip-flops',
          },
          {
            title: 'Flats',
            href: '/catalog/men/shoes/flats',
          },
          {
            title: 'Espadrilles',
            href: '/catalog/men/shoes/espadrilles',
          },
        ],
      },
      {
        title: 'Clothing',
        href: '/catalog/men/clothes',
        items: [
          {
            title: 'Trousers and Shorts',
            href: '/catalog/men/clothes/trousers-and-shorts',
          },
          {
            title: 'Outerwear',
            href: '/catalog/men/clothes/outerwear',
          },
          {
            title: 'Jeans',
            href: '/catalog/men/clothes/jeans',
          },
          {
            title: 'Suits',
            href: '/catalog/men/clothes/suits',
          },
          {
            title: 'T-shirts and Singlets',
            href: '/catalog/men/clothes/t-shirts-and-singlets',
          },
          {
            title: 'Underwear and Homewear',
            href: '/catalog/men/linergie-and-homewear',
          },
          {
            title: 'Jackets',
            href: '/catalog/men/clothes/jackets',
          },
        ],
      },
      {
        title: 'Bags',
        href: '/catalog/men/bags',
        items: [
          {
            title: 'Wrist Bags',
            href: '/catalog/men/bags/wrist-bags',
          },
          {
            title: 'Document folders',
            href: '/catalog/men/bags/document-folders',
          },
          {
            title: 'Briefcases',
            href: '/catalog/men/bags/briefcases',
          },
          {
            title: 'Belt Bags',
            href: '/catalog/men/bags/belt-bags',
          },
          {
            title: 'Backpacks',
            href: '/catalog/men/bags/backpacks',
          },
          {
            title: 'Sports and Travel Bags',
            href: '/catalog/men/bags/sports-and-travel-bags',
          },
          {
            title: 'Totes',
            href: '/catalog/men/bags/totes',
          },
        ],
      },
    ],
  },
  {
    title: 'Brands',
    href: '/brands/men',
    items: [
      {
        title: 'Brands',
        items: [
          {
            title: 'ADIDAS YEEZY',
            href: '/catalog/adidasyeezy/men',
          },
          {
            title: 'BRIONI',
            href: '/catalog/brioni/men',
          },
          {
            title: 'BRUNELLO CUCINELLI',
            href: '/catalog/brunellocucinelli/men',
          },
          {
            title: 'DOLCE&GABBANA',
            href: '/catalog/dolcegabbana/men',
          },
          {
            title: 'DSQUARED2',
            href: '/catalog/dsquared2/men',
          },
          {
            title: 'EMPORIO ARMANI',
            href: '/catalog/emporioarmani/men',
          },
          {
            title: 'GUCCI',
            href: '/catalog/gucci/men',
          },
          {
            title: 'HUGO BOSS',
            href: '/catalog/hugoboss/men',
          },
        ],
      },
      {
        href: '/brands/men',
        items: [
          {
            title: 'JOHN RICHMOND',
            href: '/catalog/johnrichmond/men',
          },
          {
            title: 'LOUIS VUITTON',
            href: '/catalog/louis-vuitton/men',
          },
          {
            title: 'PHILIPP PLEIN',
            href: '/catalog/philippplein/men',
          },
          {
            title: 'PRADA',
            href: '/catalog/prada/men',
          },
          {
            title: 'TOM FORD',
            href: '/catalog/tom-ford/men',
          },
          {
            title: 'ZILLI',
            href: '/catalog/zilli/men',
          },
        ],
      },
    ],
  },
  {
    title: 'Accessories',
    href: '/catalog/men/accessories',
    items: [
      {
        title: 'Accessories',
        items: [
          {
            title: 'Smartphone accessories',
            href: '/catalog/men/accessories/smartphone-accessories',
          },
          {
            title: 'Keyrings',
            href: '/catalog/men/accessories/keyrings',
          },
          {
            title: 'Business Card Holders',
            href: '/catalog/men/accessories/business-card-holders',
          },
          {
            title: 'Ties and Bow ties',
            href: '/catalog/men/accessories/ties-and-bow-ties',
          },
          {
            title: 'Hats',
            href: '/catalog/men/accessories/hats',
          },
          {
            title: 'Key Cases',
            href: '/catalog/men/accessories/housekeepers',
          },
          {
            title: 'Cardholders',
            href: '/catalog/men/accessories/wallets-and-cardholders',
          },
        ],
      },
      {
        href: '/catalog/men/accessories',
        items: [
          {
            title: 'Glasses',
            href: '/catalog/men/accessories/glasses',
          },
          {
            title: 'Gloves',
            href: '/catalog/men/accessories/gloves',
          },
          {
            title: 'Belts',
            href: '/catalog/men/accessories/belts',
          },
          {
            title: 'Pens',
            href: '/catalog/men/accessories/pens',
          },
          {
            title: 'Jewelry',
            href: '/catalog/men/accessories/jewelry',
          },
          {
            title: 'Watches',
            href: '/catalog/men/accessories/watches',
          },
          {
            title: 'Scarves',
            href: '/catalog/men/accessories/scarves',
          },
        ],
      },
    ],
  },
  {
    title: 'Beauty',
    href: '/catalog/men/beauty',
    items: [
      {
        title: 'Beauty',
        href: '/catalog/men/beauty',
        items: [
          {
            title: 'Accessories',
            href: '/catalog/men/beauty/accessories',
          },
          {
            title: 'Barbershop',
            href: '/catalog/men/beauty/barbershop',
          },
          {
            title: 'Deodorants',
            href: '/catalog/men/beauty/deodorants',
          },
          {
            title: 'Fragrance',
            href: '/catalog/men/beauty/fragrance',
          },
        ],
      },
    ],
  },
  {
    title: 'Shoes',
    href: '/catalog/men/shoes',
    items: [
      {
        title: 'Shoes',
        href: '/catalog/men/shoes',
        items: [
          {
            title: 'Boots',
            href: '/catalog/men/shoes/boots',
          },
          {
            title: 'Sneakers',
            href: '/catalog/men/shoes/sneakers',
          },
          {
            title: 'Loafers and Moccasins',
            href: '/catalog/men/shoes/loafers-and-moccasins',
          },
          {
            title: 'Sandals and Flip flops',
            href: '/catalog/men/shoes/sandals-and-flip-flops',
          },
          {
            title: 'Flats',
            href: '/catalog/men/shoes/flats',
          },
          {
            title: 'Espadrilles',
            href: '/catalog/men/shoes/espadrilles',
          },
        ],
      },
    ],
  },
  {
    title: 'Clothing',
    href: '/catalog/men/clothes',
    items: [
      {
        title: 'Clothing',
        items: [
          {
            title: 'Trousers and Shorts',
            href: '/catalog/men/clothes/trousers-and-shorts',
          },
          {
            title: 'Outerwear',
            href: '/catalog/men/clothes/outerwear',
          },
          {
            title: 'Jeans',
            href: '/catalog/men/clothes/jeans',
          },
          {
            title: 'Suits',
            href: '/catalog/men/clothes/suits',
          },
          {
            title: 'T-shirts and Singlets',
            href: '/catalog/men/clothes/t-shirts-and-singlets',
          },
          {
            title: 'Underwear and Homewear',
            href: '/catalog/men/linergie-and-homewear',
          },
          {
            title: 'Jackets',
            href: '/catalog/men/clothes/jackets',
          },
        ],
      },
      {
        href: '/catalog/men/clothes',
        items: [
          {
            title: 'Swimwear',
            href: '/catalog/men/clothes/swimwear',
          },
          {
            title: 'Shirts',
            href: '/catalog/men/clothes/shirts',
          },
          {
            title: 'Sportswear',
            href: '/catalog/men/clothes/sportswear',
          },
          {
            title: 'Knitwear',
            href: '/catalog/men/clothes/knitwear',
          },
          {
            title: 'Hoodies and Sweatshirts',
            href: '/catalog/men/clothes/hoodies-and-sweatshirts',
          },
        ],
      },
    ],
  },
  {
    title: 'Bags',
    href: '/catalog/men/bags',
    items: [
      {
        title: 'Bags',
        items: [
          {
            title: 'Wrist Bags',
            href: '/catalog/men/bags/wrist-bags',
          },
          {
            title: 'Document folders',
            href: '/catalog/men/bags/document-folders',
          },
          {
            title: 'Briefcases',
            href: '/catalog/men/bags/briefcases',
          },
          {
            title: 'Belt bags',
            href: '/catalog/men/bags/belt-bags',
          },
          {
            title: 'Backpacks',
            href: '/catalog/men/bags/backpacks',
          },
          {
            title: 'Sports and Travel Bags',
            href: '/catalog/men/bags/sports-and-travel-bags',
          },
          {
            title: 'Totes',
            href: '/catalog/men/bags/totes',
          },
        ],
      },
      {
        href: '/catalog/men/bags',
        items: [
          {
            title: 'Shoulder Bags',
            href: '/catalog/men/bags/shoulder-bags',
          },
        ],
      },
    ],
  },
  {
    title: 'Jewelry',
    href: '/catalog/men/jewelry',
    items: [
      {
        title: 'Jewelry',
        href: '/catalog/men/jewelry',
        items: [
          {
            title: 'Bracelets',
            href: '/catalog/men/jewelry/bracelets',
          },
          {
            title: 'Cufflinks',
            href: '/catalog/men/jewelry/cufflinks',
          },
          {
            title: 'Rings',
            href: '/catalog/men/jewelry/rings',
          },
          {
            title: 'Earrings',
            href: '/catalog/men/jewelry/earrings',
          },
          {
            title: 'Necklaces',
            href: '/catalog/men/jewelry/necklaces',
          },
          {
            title: 'Watches',
            href: '/catalog/men/jewelry/watches',
          },
        ],
      },
    ],
  },
];
