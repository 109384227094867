import { ICategoryMenu } from '~/types/menu';

export const kidsEN: ICategoryMenu[] = [
  {
    title: 'OSKELLY Favorites',
    href: '/catalog/kids?oskellyChoice=true',
    items: [
      {
        title: 'Girls 0-3',
        href: '/catalog/kids/girls-0-3?oskellyChoice=true',
        items: [
          {
            title: 'Shoes',
            href: '/catalog/kids/girls-0-3/shoes?oskellyChoice=true',
          },
          {
            title: 'Clothes',
            href: '/catalog/kids/girls-0-3/clothes?oskellyChoice=true',
          },
        ],
      },
      {
        title: 'Girls 4-14',
        href: '/catalog/kids/girls-4-14?oskellyChoice=true',
        items: [
          {
            title: 'Accessories',
            href: '/catalog/kids/girls-4-14/accessories?oskellyChoice=true',
          },
          {
            title: 'Shoes',
            href: '/catalog/kids/girls-4-14/shoes?oskellyChoice=true',
          },
          {
            title: 'Clothing',
            href: '/catalog/kids/girls-4-14/clothes?oskellyChoice=true',
          },
        ],
      },
      {
        title: 'Boys 0-3',
        href: '/catalog/kids/boys-0-3?oskellyChoice=true',
        items: [
          {
            title: 'Shoes',
            href: '/catalog/kids/boys-0-3/shoes?oskellyChoice=true',
          },
        ],
      },
      {
        title: 'Boys 4-14',
        href: '/catalog/kids/boys-4-14?oskellyChoice=true',
        items: [
          {
            title: 'Shoes',
            href: '/catalog/kids/boys-4-14/shoes?oskellyChoice=true',
          },
        ],
      },
    ],
  },
  {
    title: 'New In',
    href: '/catalog/kids',
    items: [
      {
        title: 'Girls 0-3',
        href: '/catalog/kids/girls-0-3',
        items: [
          {
            title: 'Accessories',
            href: '/catalog/kids/girls-0-3/accessories',
          },
          {
            title: 'Shoes',
            href: '/catalog/kids/girls-0-3/shoes',
          },
          {
            title: 'Clothes',
            href: '/catalog/kids/girls-0-3/clothes',
          },
        ],
      },
      {
        title: 'Girls 4-14',
        href: '/catalog/kids/girls-4-14',
        items: [
          {
            title: 'Accessories',
            href: '/catalog/kids/girls-4-14/accessories',
          },
          {
            title: 'Shoes',
            href: '/catalog/kids/girls-4-14/shoes',
          },
          {
            title: 'Clothing',
            href: '/catalog/kids/girls-4-14/clothes',
          },
          {
            title: 'Bags',
            href: '/catalog/kids/girls-4-14/bags',
          },
        ],
      },
      {
        title: 'Boys 0-3',
        href: '/catalog/kids/boys-0-3',
        items: [
          {
            title: 'Accessories and Gifts',
            href: '/catalog/kids/boys-0-3/accessories-and-gifts',
          },
          {
            title: 'Shoes',
            href: '/catalog/kids/boys-0-3/shoes',
          },
          {
            title: 'Clothes',
            href: '/catalog/kids/boys-0-3/clothes',
          },
        ],
      },
      {
        title: 'Boys 4-14',
        href: '/catalog/kids/boys-4-14',
        items: [
          {
            title: 'Accessories',
            href: '/catalog/kids/boys-4-14/accessories',
          },
          {
            title: 'Shoes',
            href: '/catalog/kids/boys-4-14/shoes',
          },
          {
            title: 'Clothing',
            href: '/catalog/kids/boys-4-14/clothes',
          },
          {
            title: 'Bags',
            href: '/catalog/kids/boys-4-14/bags',
          },
        ],
      },
    ],
  },
  {
    title: 'Brands',
    href: '/brands/kids',
    items: [
      {
        title: 'Brands',
        items: [
          {
            title: 'ARMANI JUNIOR',
            href: '/catalog/armani-junior/kids',
          },
          {
            title: 'BONPOINT',
            href: '/catalog/bonpoint/kids',
          },
          {
            title: 'BURBERRY',
            href: '/catalog/burberry/kids',
          },
          {
            title: 'DOLCE & GABBANA KIDS',
            href: '/catalog/dolce--gabbana-kids/kids',
          },
          {
            title: 'DOLCE & GABBANA',
            href: '/catalog/dolcegabbana/kids',
          },
          {
            title: 'GUCCI',
            href: '/catalog/gucci/kids',
          },
          {
            title: 'GUCCI KIDS',
            href: '/catalog/guccikids/kids',
          },
          {
            title: 'IL GUFO',
            href: '/catalog/ilgufo/kids',
          },
        ],
      },
      {
        href: '/brands/kids',
        items: [
          {
            title: 'MISS BLUMARINE',
            href: '/catalog/miss-blumarine/kids',
          },
          {
            title: 'MONCLER',
            href: '/catalog/moncler/kids',
          },
          {
            title: 'MONNALISA',
            href: '/catalog/monnalisa/kids',
          },
          {
            title: 'POLO RALPH LAUREN',
            href: '/catalog/poloralphlauren/kids',
          },
          {
            title: 'RALPH LAUREN',
            href: '/catalog/ralphlauren/kids',
          },
          {
            title: 'ZHANNA & ANNA',
            href: '/catalog/zhannaanna/kids',
          },
        ],
      },
    ],
  },
  {
    title: 'Girls 0-3',
    href: '/catalog/kids/girls-0-3',
    items: [
      {
        title: 'Girls 0-3',
        href: '/catalog/kids/girls-0-3',
        items: [
          {
            title: 'Accessories',
            href: '/catalog/kids/girls-0-3/accessories',
          },
          {
            title: 'Shoes',
            href: '/catalog/kids/girls-0-3/shoes',
          },
          {
            title: 'Clothes',
            href: '/catalog/kids/girls-0-3/clothes',
          },
        ],
      },
    ],
  },
  {
    title: 'Girls 4-14',
    href: '/catalog/kids/girls-4-14',
    items: [
      {
        title: 'Girls 4-14',
        href: '/catalog/kids/girls-4-14',
        items: [
          {
            title: 'Accessories',
            href: '/catalog/kids/girls-4-14/accessories',
          },
          {
            title: 'Shoes',
            href: '/catalog/kids/girls-4-14/shoes',
          },
          {
            title: 'Clothing',
            href: '/catalog/kids/girls-4-14/clothes',
          },
          {
            title: 'Bags',
            href: '/catalog/kids/girls-4-14/bags',
          },
        ],
      },
    ],
  },
  {
    title: 'Boys 0-3',
    href: '/catalog/kids/boys-0-3',
    items: [
      {
        title: 'Boys 0-3',
        href: '/catalog/kids/boys-0-3',
        items: [
          {
            title: 'Accessories and Gifts',
            href: '/catalog/kids/boys-0-3/accessories-and-gifts',
          },
          {
            title: 'Shoes',
            href: '/catalog/kids/boys-0-3/shoes',
          },
          {
            title: 'Clothes',
            href: '/catalog/kids/boys-0-3/clothes',
          },
        ],
      },
    ],
  },
  {
    title: 'Boys 4-14',
    href: '/catalog/kids/boys-4-14',
    items: [
      {
        title: 'Boys 4-14',
        href: '/catalog/kids/boys-4-14',
        items: [
          {
            title: 'Accessories',
            href: '/catalog/kids/boys-4-14/accessories',
          },
          {
            title: 'Shoes',
            href: '/catalog/kids/boys-4-14/shoes',
          },
          {
            title: 'Clothing',
            href: '/catalog/kids/boys-4-14/clothes',
          },
          {
            title: 'Bags',
            href: '/catalog/kids/boys-4-14/bags',
          },
        ],
      },
    ],
  },
];
