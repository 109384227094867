<template>
  <div class="osk-header-search-empty">
    <template v-if="isDesktop">
      {{ $t('components.header.headerSearchResults.empty') }}
    </template>

    <template v-else>
      <div class="osk-header-search-empty__title">
        {{ $t('components.header.headerSearchResults.empty.mobile.title') }}
      </div>

      <div class="osk-header-search-empty__text">
        {{ $t('components.header.headerSearchResults.empty.mobile.text') }}
      </div>

      <template v-if="isVisibleBanner">
        <HeaderSearchBanner
          class="osk-header-search-empty__banner"
          @click-link="$emit('clickLink')"
        />
      </template>
    </template>
  </div>
</template>

<script setup lang="ts">
const { isDesktop } = useUiBreakpoints();
const { isInternational } = useInternational();
const { FULLTEXT_SEARCH_WEB_ON } = useExperimentsStore();

const emits = defineEmits<{
  (e: 'clickLink'): void,
}>();

const isVisibleBanner = computed(() => FULLTEXT_SEARCH_WEB_ON.value && !isInternational);
</script>

<style lang="scss">
@import "~/assets/scss/settings/index";

.osk-header-search-empty {
  margin: var(--indent-xxlarge) 0 var(--indent-mlarge);

  @include media-query(lg-and-up) {
    margin-bottom: calc(var(--indent-xxlarge) * 2);
  }

  @include media-query(lg-and-down) {
    margin-top: var(--indent-mlarge);
  }

  &__title {
    @include font-style(var(--font-size-heading), var(--line-height-heading), var(--font-weight-bold));
    margin-bottom: 14px;
  }

  &__text {
    @include font-style(var(--font-size-body), 18px, var(--font-weight-medium));
    color: var(--grey);
  }

  &__banner {
    display: block;
    margin-top: var(--indent-mlarge);
    margin-bottom: var(--indent-mlarge);
  }
}
</style>
