import { ICategoryMenu } from '~/types/menu';

export const womenEN: ICategoryMenu[] = [
  {
    title: 'OSKELLY Favorites',
    href: '/catalog/women?oskellyChoice=true',
    items: [
      {
        title: 'Accessories',
        href: '/catalog/women/accessories?oskellyChoice=true',
        items: [
          {
            title: 'Business Card Holders',
            href: '/catalog/women/accessories/business-card-holders?oskellyChoice=true',
          },
          {
            title: 'Hats',
            href: '/catalog/women/accessories/hats?oskellyChoice=true',
          },
          {
            title: 'Wallets and Cardholders',
            href: '/catalog/women/accessories/wallets-and-cardholders?oskellyChoice=true',
          },
          {
            title: 'Rims',
            href: '/catalog/women/accessories/rims?oskellyChoice=true',
          },
          {
            title: 'Glasses',
            href: '/catalog/women/accessories/glasses?oskellyChoice=true',
          },
          {
            title: 'Gloves',
            href: '/catalog/women/accessories/gloves?oskellyChoice=true',
          },
          {
            title: 'Headbands',
            href: '/catalog/women/accessories/headbands?oskellyChoice=true',
          },
        ],
      },
      {
        title: 'Beauty',
        href: '/catalog/women/beauty?oskellyChoice=true',
        items: [
          {
            title: 'Fragrance',
            href: '/catalog/women/beauty/fragrance?oskellyChoice=true',
          },
        ],
      },
      {
        title: 'Shoes',
        href: '/catalog/women/shoes?oskellyChoice=true',
        items: [
          {
            title: 'Flip Flops',
            href: '/catalog/women/shoes/flip-flops?oskellyChoice=true',
          },
          {
            title: 'Sandals',
            href: '/catalog/women/shoes/sandals?oskellyChoice=true',
          },
          {
            title: 'Ankle Boots',
            href: '/catalog/women/shoes/ankle-boots?oskellyChoice=true',
          },
          {
            title: 'Boots',
            href: '/catalog/women/shoes/boots?oskellyChoice=true',
          },
          {
            title: 'Brogues and Oxfords',
            href: '/catalog/women/shoes/brogues-and-oxfords?oskellyChoice=true',
          },
          {
            title: 'Sneakers',
            href: '/catalog/women/shoes/sneakers?oskellyChoice=true',
          },
          {
            title: 'Low-heeled Pumps',
            href: '/catalog/women/shoes/low-heel-pumps?oskellyChoice=true',
          },
        ],
      },
      {
        title: 'Clothes',
        href: '/catalog/women/clothes?oskellyChoice=true',
        items: [
          {
            title: 'Pants',
            href: '/catalog/women/clothes/pants?oskellyChoice=true',
          },
          {
            title: 'Outerwear',
            href: '/catalog/women/clothes/outerwear?oskellyChoice=true',
          },
          {
            title: 'Knitwear',
            href: '/catalog/women/clothes/knitwear?oskellyChoice=true',
          },
          {
            title: 'Jeans',
            href: '/catalog/women/clothes/jeans?oskellyChoice=true',
          },
          {
            title: 'Blazers and Jackets',
            href: '/catalog/women/clothes/blazers-and-jackets?oskellyChoice=true',
          },
          {
            title: 'Suits',
            href: '/catalog/women/clothes/suits?oskellyChoice=true',
          },
          {
            title: 'Tops',
            href: '/catalog/women/clothes/tops?oskellyChoice=true',
          },
        ],
      },
      {
        title: 'Bags',
        href: '/catalog/women/bags?oskellyChoice=true',
        items: [
          {
            title: 'Travel and Sports Bags',
            href: '/catalog/women/bags/travel-and-sports-bags?oskellyChoice=true',
          },
          {
            title: 'Clutches and Pouches',
            href: '/catalog/women/bags/clutches-and-pouches?oskellyChoice=true',
          },
          {
            title: 'Beach Bags',
            href: '/catalog/women/bags/beach-bags?oskellyChoice=true',
          },
          {
            title: 'Belt Bags',
            href: '/catalog/women/bags/belt-bags?oskellyChoice=true',
          },
          {
            title: 'Backpacks',
            href: '/catalog/women/bags/backpacks?oskellyChoice=true',
          },
          {
            title: 'Short handle Bags',
            href: '/catalog/women/bags/short-handle-bags?oskellyChoice=true',
          },
          {
            title: 'Totes',
            href: '/catalog/women/bags/totes?oskellyChoice=true',
          },
        ],
      },
    ],
  },
  {
    title: 'New In',
    href: '/catalog/women',
    items: [
      {
        title: 'Accessories',
        href: '/catalog/women/accessories',
        items: [
          {
            title: 'Hair Accessories',
            href: '/catalog/women/accessories/hair-accessories',
          },
          {
            title: 'Accessories for Equipment',
            href: '/catalog/women/accessories/accessories-for-equipment',
          },
          {
            title: 'Key Rings',
            href: '/catalog/women/accessories/key-rings',
          },
          {
            title: 'Business Card Holders',
            href: '/catalog/women/accessories/business-card-holders',
          },
          {
            title: 'Hats',
            href: '/catalog/women/accessories/hats',
          },
          {
            title: 'Umbrellas',
            href: '/catalog/women/accessories/umbrellas',
          },
          {
            title: 'Housekeepers',
            href: '/catalog/women/accessories/housekeepers',
          },
        ],
      },
      {
        title: 'Beauty',
        href: '/catalog/women/beauty',
        items: [
          {
            title: 'Gadgets',
            href: '/catalog/women/beauty/gadgets',
          },
          {
            title: 'Makeup',
            href: '/catalog/women/beauty/makeup',
          },
          {
            title: 'Fragrance',
            href: '/catalog/women/beauty/fragrance',
          },
          {
            title: 'Hair Care',
            href: '/catalog/women/beauty/hair-care',
          },
          {
            title: 'Skinncare',
            href: '/catalog/women/beauty/skinncare',
          },
          {
            title: 'Body Care',
            href: '/catalog/women/beauty/body-care',
          },
        ],
      },
      {
        title: 'Shoes',
        href: '/catalog/women/shoes',
        items: [
          {
            title: 'Flip Flops',
            href: '/catalog/women/shoes/flip-flops',
          },
          {
            title: 'Sandals',
            href: '/catalog/women/shoes/sandals',
          },
          {
            title: 'Ankle Boots',
            href: '/catalog/women/shoes/ankle-boots',
          },
          {
            title: 'Boots',
            href: '/catalog/women/shoes/boots',
          },
          {
            title: 'Brogues and Oxfords',
            href: '/catalog/women/shoes/brogues-and-oxfords',
          },
          {
            title: 'Sneakers',
            href: '/catalog/women/shoes/sneakers',
          },
          {
            title: 'Low-heeled Pumps',
            href: '/catalog/women/shoes/low-heel-pumps',
          },
        ],
      },
      {
        title: 'Clothes',
        href: '/catalog/women/clothes',
        items: [
          {
            title: 'Pants',
            href: '/catalog/women/clothes/pants',
          },
          {
            title: 'Outerwear',
            href: '/catalog/women/clothes/outerwear',
          },
          {
            title: 'Knitwear',
            href: '/catalog/women/clothes/knitwear',
          },
          {
            title: 'Jeans',
            href: '/catalog/women/clothes/jeans',
          },
          {
            title: 'Blazers and Jackets',
            href: '/catalog/women/clothes/blazers-and-jackets',
          },
          {
            title: 'Capris',
            href: '/catalog/women/clothes/capris',
          },
          {
            title: 'Jumpsuits',
            href: '/catalog/women/clothes/jumpsuits',
          },
        ],
      },
      {
        title: 'Bags',
        href: '/catalog/women/bags',
        items: [
          {
            title: 'Bag Accessories',
            href: '/catalog/women/bags/bag-accessories',
          },
          {
            title: 'Travel and Sports Bags',
            href: '/catalog/women/bags/travel-and-sports-bags',
          },
          {
            title: 'Clutches and Pouches',
            href: '/catalog/women/bags/clutches-and-pouches',
          },
          {
            title: 'Beach Bags',
            href: '/catalog/women/bags/beach-bags',
          },
          {
            title: 'Belt Bags',
            href: '/catalog/women/bags/belt-bags',
          },
          {
            title: 'Backpacks',
            href: '/catalog/women/bags/backpacks',
          },
          {
            title: 'Short handle Bags',
            href: '/catalog/women/bags/short-handle-bags',
          },
        ],
      },
    ],
  },
  {
    title: 'Brands',
    href: '/brands/women',
    items: [
      {
        title: 'Brands',
        items: [
          {
            title: 'BALENCIAGA',
            href: '/catalog/balenciaga/women',
          },
          {
            title: 'CELINE',
            href: '/catalog/celine/women',
          },
          {
            title: 'CHANEL',
            href: '/catalog/chanel/women',
          },
          {
            title: 'CHRISTIAN DIOR',
            href: '/catalog/christian-dior/women',
          },
          {
            title: 'CHRISTIAN LOUBOUTIN',
            href: '/catalog/christianlouboutin/women',
          },
          {
            title: 'DOLCE&GABBANA',
            href: '/catalog/dolcegabbana/women',
          },
          {
            title: 'FENDI',
            href: '/catalog/fendi/women',
          },
          {
            title: 'GUCCI',
            href: '/catalog/gucci/women',
          },
        ],
      },
      {
        href: '/brands/women',
        items: [
          {
            title: 'LOUIS VUITTON',
            href: '/catalog/louis-vuitton/women',
          },
          {
            title: 'MICHAEL KORS',
            href: '/catalog/michaelkors/women',
          },
          {
            title: 'PINKO',
            href: '/catalog/pinko/women',
          },
          {
            title: 'PRADA',
            href: '/catalog/prada/women',
          },
          {
            title: 'SAINT LAURENT',
            href: '/catalog/saintlaurent/women',
          },
          {
            title: 'VALENTINO',
            href: '/catalog/valentino/women',
          },
        ],
      },
    ],
  },
  {
    title: 'Accessories',
    href: '/catalog/women/accessories',
    items: [
      {
        title: 'Accessories',
        items: [
          {
            title: 'Hair Accessories',
            href: '/catalog/women/accessories/hair-accessories',
          },
          {
            title: 'Accessories for Equipment',
            href: '/catalog/women/accessories/accessories-for-equipment',
          },
          {
            title: 'Key Rings',
            href: '/catalog/women/accessories/key-rings',
          },
          {
            title: 'Business Card Holders',
            href: '/catalog/women/accessories/business-card-holders',
          },
          {
            title: 'Hats',
            href: '/catalog/women/accessories/hats',
          },
          {
            title: 'Umbrellas',
            href: '/catalog/women/accessories/umbrellas',
          },
          {
            title: 'Housekeepers',
            href: '/catalog/women/accessories/housekeepers',
          },
        ],
      },
      {
        items: [
          {
            title: 'Cosmetic Bags',
            href: '/catalog/women/accessories/cosmetic-bags',
          },
          {
            title: 'Wallets and Cardholders',
            href: '/catalog/women/accessories/wallets-and-cardholders',
          },
          {
            title: 'Covers and Cases',
            href: '/catalog/women/accessories/covers-and-cases',
          },
          {
            title: 'Rims',
            href: '/catalog/women/accessories/rims',
          },
          {
            title: 'Glasses',
            href: '/catalog/women/accessories/glasses',
          },
          {
            title: 'Gloves',
            href: '/catalog/women/accessories/gloves',
          },
          {
            title: 'Headbands',
            href: '/catalog/women/accessories/headbands',
          },
        ],
      },
      {
        href: '/catalog/women/accessories',
        items: [
          {
            title: 'Belts',
            href: '/catalog/women/accessories/belts',
          },
          {
            title: 'Scarves',
            href: '/catalog/women/accessories/scarves',
          },
        ],
      },
    ],
  },
  {
    title: 'Beauty',
    href: '/catalog/women/beauty',
    items: [
      {
        title: 'Beauty',
        href: '/catalog/women/beauty',
        items: [
          {
            title: 'Gadgets',
            href: '/catalog/women/beauty/gadgets',
          },
          {
            title: 'Makeup',
            href: '/catalog/women/beauty/makeup',
          },
          {
            title: 'Fragrance',
            href: '/catalog/women/beauty/fragrance',
          },
          {
            title: 'Hair Care',
            href: '/catalog/women/beauty/hair-care',
          },
          {
            title: 'Skinncare',
            href: '/catalog/women/beauty/skinncare',
          },
          {
            title: 'Body Care',
            href: '/catalog/women/beauty/body-care',
          },
        ],
      },
    ],
  },
  {
    title: 'Shoes',
    href: '/catalog/women/shoes',
    items: [
      {
        title: 'Shoes',
        items: [
          {
            title: 'Flip Flops',
            href: '/catalog/women/shoes/flip-flops',
          },
          {
            title: 'Sandals',
            href: '/catalog/women/shoes/sandals',
          },
          {
            title: 'Ankle Boots',
            href: '/catalog/women/shoes/ankle-boots',
          },
          {
            title: 'Boots',
            href: '/catalog/women/shoes/boots',
          },
          {
            title: 'Brogues and Oxfords',
            href: '/catalog/women/shoes/brogues-and-oxfords',
          },
          {
            title: 'Sneakers',
            href: '/catalog/women/shoes/sneakers',
          },
          {
            title: 'Low-heeled pumps',
            href: '/catalog/women/shoes/low-heel-pumps',
          },
          {
            title: 'Loafers and Moccasins',
            href: '/catalog/women/shoes/loafers-and-moccasins',
          },
        ],
      },
      {
        href: '/catalog/women/shoes',
        items: [
          {
            title: 'Mules',
            href: '/catalog/women/shoes/mules',
          },
          {
            title: 'Sandals and Flip flops',
            href: '/catalog/women/shoes/sandals-and-flip-flops',
          },
          {
            title: 'Boots and Thigh-High Boots',
            href: '/catalog/women/shoes/boots-and-thigh-high-boots',
          },
          {
            title: 'Wedding Shoes',
            href: '/catalog/women/shoes/wedding-shoes',
          },
          {
            title: 'Pumps',
            href: '/catalog/women/shoes/pumps',
          },
          {
            title: 'Espadrilles',
            href: '/catalog/women/shoes/espadrilles',
          },
        ],
      },
    ],
  },
  {
    title: 'Clothes',
    href: '/catalog/women/clothes',
    items: [
      {
        title: 'Clothes',
        items: [
          {
            title: 'Pants',
            href: '/catalog/women/clothes/pants',
          },
          {
            title: 'Outerwear',
            href: '/catalog/women/clothes/outerwear',
          },
          {
            title: 'Knitwear',
            href: '/catalog/women/clothes/knitwear',
          },
          {
            title: 'Jeans',
            href: '/catalog/women/clothes/jeans',
          },
          {
            title: 'Blazers and Jackets',
            href: '/catalog/women/clothes/blazers-and-jackets',
          },
          {
            title: 'Capris',
            href: '/catalog/women/clothes/capris',
          },
          {
            title: 'Jumpsuits',
            href: '/catalog/women/clothes/jumpsuits',
          },
        ],
      },
      {
        items: [
          {
            title: 'Corsets',
            href: '/catalog/women/clothes/corsets',
          },
          {
            title: 'Suits',
            href: '/catalog/women/clothes/suits',
          },
          {
            title: 'Tops',
            href: '/catalog/women/clothes/tops',
          },
          {
            title: 'Linergie and Homewear',
            href: '/catalog/women/clothes/linergie-and-homewear',
          },
          {
            title: 'Dresses',
            href: '/catalog/women/clothes/dresses',
          },
          {
            title: 'Beachwear',
            href: '/catalog/women/clothes/beachwear',
          },
          {
            title: 'Shirts and Blouses',
            href: '/catalog/women/clothes/shirts-and-blouses',
          },
        ],
      },
      {
        href: '/catalog/women/clothes',
        items: [
          {
            title: 'Sportswear',
            href: '/catalog/women/clothes/sportswear',
          },
          {
            title: 'Hoodies and Sweatshirts',
            href: '/catalog/women/clothes/hoodies-and-sweatshirts',
          },
          {
            title: 'Shorts',
            href: '/catalog/women/clothes/shorts',
          },
          {
            title: 'Skirts',
            href: '/catalog/women/clothes/skirts',
          },
        ],
      },
    ],
  },
  {
    title: 'Bags',
    href: '/catalog/women/bags',
    items: [
      {
        title: 'Bags',
        items: [
          {
            title: 'Bag Accessories',
            href: '/catalog/women/bags/bag-accessories',
          },
          {
            title: 'Travel and Sports bags',
            href: '/catalog/women/bags/travel-and-sports-bags',
          },
          {
            title: 'Clutches and Pouches',
            href: '/catalog/women/bags/clutches-and-pouches',
          },
          {
            title: 'Beach Bags',
            href: '/catalog/women/bags/beach-bags',
          },
          {
            title: 'Belt Bags',
            href: '/catalog/women/bags/belt-bags',
          },
          {
            title: 'Backpacks',
            href: '/catalog/women/bags/backpacks',
          },
          {
            title: 'Short handle Bags',
            href: '/catalog/women/bags/short-handle-bags',
          },
        ],
      },
      {
        href: '/catalog/women/bags',
        items: [
          {
            title: 'Totes',
            href: '/catalog/women/bags/totes',
          },
          {
            title: 'Crossbody Bags',
            href: '/catalog/women/bags/crossbody-bags',
          },
        ],
      },
    ],
  },
  {
    title: 'Jewelry',
    href: '/catalog/women/jewelry',
    items: [
      {
        title: 'Jewelry',
        href: '/catalog/women/jewelry',
        items: [
          {
            title: 'Pins and Brooches',
            href: '/catalog/women/accessories/jewelry/pins-and-brooches',
          },
          {
            title: 'Other',
            href: '/catalog/women/accessories/jewelry/other',
          },
          {
            title: 'Cufflinks',
            href: '/catalog/women/jewelry/cufflinks',
          },
          {
            title: 'Clips',
            href: '/catalog/women/jewelry/clips',
          },
          {
            title: 'Rings',
            href: '/catalog/women/accessories/jewelry/rings',
          },
          {
            title: 'Sets',
            href: '/catalog/women/jewelry/sets',
          },
          {
            title: 'Chains and Pendants',
            href: '/catalog/women/jewelry/chains-and-pendants',
          },
          {
            title: 'Watch',
            href: '/catalog/women/jewelry/watches',
          },
        ],
      },
    ],
  },
  {
    title: 'High Jewelry',
    href: '/catalog/women/high-jewelry',
    items: [
      {
        title: 'High Jewelry',
        href: '/catalog/women/high-jewelry',
        items: [
          {
            title: 'Bracelets',
            href: '/catalog/women/high-jewelry/bracelets',
          },
          {
            title: 'Brooches',
            href: '/catalog/women/high-jewelry/brooches',
          },
          {
            title: 'Cufflinks',
            href: '/catalog/women/high-jewelry/cufflinks',
          },
          {
            title: 'Rings',
            href: '/catalog/women/high-jewelry/rings',
          },
          {
            title: 'Sets',
            href: '/catalog/women/high-jewelry/sets',
          },
          {
            title: 'Earrings',
            href: '/catalog/women/high-jewelry/earrings',
          },
          {
            title: 'Chains and Pendants',
            href: '/catalog/women/high-jewelry/chains-and-pendants',
          },
          {
            title: 'Watch',
            href: '/catalog/women/high-jewelry/watches',
          },
        ],
      },
    ],
  },
];
