<template>
  <div class="osk-header-search-history">
    <div class="osk-header-search-history__title">
      {{ $t('components.header.headerSearchResults.title') }}
    </div>

    <HeaderSearchSuggestions
      :has-padding="hasPadding"
      :products
      :users
      @product-click="handleProductClick"
      @user-click="handleUserClick"
    />
  </div>
</template>

<script setup lang="ts">
import {
  UserSearchItem,
  TProductSearchItem,
  ProductSearchItem,
} from '~/types/components/search';

const { isDesktop } = useUiBreakpoints();

defineProps<{
  hasPadding: boolean,
  products: ProductSearchItem[] | TProductSearchItem[] | undefined,
  users: UserSearchItem[] | undefined,
}>();

const emits = defineEmits<{
  (e: 'product-click', item: ProductSearchItem | TProductSearchItem): void,
  (e: 'user-click', item: UserSearchItem): void,
}>();

function handleProductClick(item: ProductSearchItem | TProductSearchItem) {
  emits('product-click', item);
}

function handleUserClick(item: UserSearchItem) {
  emits('user-click', item);
}
</script>

<style lang="scss">
@import "~/assets/scss/settings/index";

.osk-header-search-history {
  width: 100%;
  height: 100%;
  margin-top: 10px;

  @include media-query(lg-and-up) {
    width: var(--width-full-search);
    margin: 0 auto -52px;
    padding: 0;
  }

  .osk-header-search-suggestions__list {
    padding-top: 6px;

    @include media-query(lg-and-up) {
      padding-top: 0;
    }
  }

  .osk-header-search-suggestions__icon {
    margin-left: auto;
  }

  &__title {
    @include font-style(18px, 22px, var(--font-weight-bold));
    margin-bottom: var(--indent-compact);
    padding-top: var(--indent-compact);

    @include media-query(lg-and-up) {
      @include font-style(var(--font-size-body), var(--line-height-body), var(--font-weight-bold));
      padding-top: 0;
    }
  }
}
</style>
