<template>
  <CustomScrollbar
    class="osk-header-search-suggestions"
    :style="{ maxHeight: isDesktop ? '248px' : '100%' }"
  >
    <ul
      :class="[
        'osk-header-search-suggestions__list',
        {
          'osk-header-search-suggestions__list_withPadding': hasPadding,
        }
      ]"
    >
      <li
        v-for="(item, index) in products"
        :key="`product-${index}`"
        class="osk-header-search-suggestions__product"
        @click="handleProductClick(item, index)"
      >
        <span class="osk-header-search-suggestions__product-title">
          <template v-if="FULLTEXT_SEARCH_WEB_ON">
            {{ item }}
          </template>

          <template v-else>
            {{ (item as ProductSearchItem).search_value }}

            <template v-if="(item as ProductSearchItem).attributes?.length">
              (<template
                v-for="(attr, index) in (item as ProductSearchItem).attributes"
                :key="index"
              >
                {{ attr?.values?.name }}<template v-if="index !== (item as ProductSearchItem).attributes.length - 1">, </template>
              </template>)
            </template>
          </template>
        </span>

        <span
          v-if="(item as ProductSearchItem).products_total"
          class="osk-header-search-suggestions__product-counter"
        >
          {{ (item as ProductSearchItem).products_total }}
        </span>

        <UikitIcon
          name="ArrowBack"
          size="s"
          class="osk-header-search-suggestions__icon"
        />
      </li>

      <li
        v-for="(item, index) in users"
        :key="`user-${index}`"
        class="osk-header-search-suggestions__user"
        @click="handleUserClick(item, index)"
      >
        <UikitUserAvatar
          class="osk-header-search-suggestions__user-avatar"
          :user="item"
        />

        <div class="osk-header-search-suggestions__user-content">
          <span>
            {{ item.nickname }}
          </span>

          <span class="osk-header-search-suggestions__user-counter">
            {{ $t('components.header.headerSearchResults.productsTotal', item.products_total) }}
          </span>
        </div>

        <UikitIcon
          name="ArrowBack"
          size="s"
          class="osk-header-search-suggestions__icon"
        />
      </li>
    </ul>
  </CustomScrollbar>
</template>

<script setup lang="ts">
import {
  UserSearchItem,
  TProductSearchItem,
  ProductSearchItem,
} from '~/types/components/search';

const { isDesktop } = useUiBreakpoints();
const { FULLTEXT_SEARCH_WEB_ON } = useExperimentsStore();

defineProps<{
  hasPadding: boolean,
  products: ProductSearchItem[] | TProductSearchItem[] | undefined,
  users: UserSearchItem[] | undefined,
}>();

const emits = defineEmits<{
  (e: 'product-click', item: ProductSearchItem | TProductSearchItem, index: number): void,
  (e: 'user-click', item: UserSearchItem, index: number): void,
}>();

function handleProductClick(item: ProductSearchItem | TProductSearchItem, index: number) {
  emits('product-click', item, index);
}

function handleUserClick(item: UserSearchItem, index: number) {
  emits('user-click', item, index);
}
</script>

<style lang="scss">
@import "~/assets/scss/settings/index";

.osk-header-search-suggestions {
  margin: 0;
  padding: 0;

  &__list {
    height: 100%;
    scrollbar-width: none;
    padding-top: var(--indent-large);

    &::-webkit-scrollbar {
      display: none;
    }

    @include media-query(lg-and-up) {
      padding-right: var(--indent-medium);
      max-height: 248px;
      height: auto;
      padding-top: 0;
    }

    &_withPadding {
      @include media-query(lg-and-up) {
        padding-bottom: var(--indent-medium);
      }
    }
  }

  &__product {
    @include font-style(var(--font-size-body), var(--line-height-body), var(--font-weight-medium));
    display: flex;
    align-items: center;
    padding: var(--indent-medium) 0;
    cursor: pointer;
  }

  &__product-title {
    padding-right: var(--indent-medium);
  }

  &__product-counter {
    @include font-style(var(--font-size-caption), var(--line-height-caption), var(--font-weight-semibold));
    margin-left: auto;
    margin-right: var(--indent-small);
    padding: var(--indent-xsmall) var(--indent-small);
    background: var(--grey-4);
    border-radius: 100px;

    & + .osk-header-search-suggestions__icon {
      margin-left: 0 !important;
    }
  }

  &__user {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 11px 0;
    cursor: pointer;

    .osk-header-search-suggestions__user-avatar {
      width: 40px;
      height: 40px;
      margin-right: var(--indent-compact);
    }
  }

  &__user-content {
    display: flex;
    flex-direction: column;
  }

  &__user-counter {
    font-weight: var(--font-weight-medium);
    font-size: 10px;
    line-height: 12px;
    margin-top: var(--indent-xsmall);
    color: var(--grey);
  }

  &__icon {
    transform: rotate(180deg);
    flex-shrink: 0;
    margin-left: auto;
  }
}
</style>
