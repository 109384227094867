<template>
  <nuxt-link
    v-if="banner"
    :to="link"
    no-prefetch
    :external="!(link?.includes('catalog') || link?.includes('homeBanner'))"
    class="osk-header-banner"
  >
    <img
      v-if="banner.imgPath"
      class="osk-header-banner__image"
      :src="banner.imgPath"
    >

    <div
      v-if="banner.title"
      class="osk-header-banner__title"
    >
      {{ banner.title }}
    </div>

    <span class="osk-header-banner__link">
      {{ $t('components.header.headerMenuBanner.link') }}
    </span>
  </nuxt-link>
</template>

<script lang="ts" setup>
import { BannerDTO } from '~/restAPI/data-contracts';

const props = defineProps<{
  banner?: BannerDTO | null
}>();

const link = computed(() => genSPALink(props.banner?.link ?? props.banner?.catalogLink));
</script>

<style scoped lang="scss">
@import "~/assets/scss/settings/index";

.osk-header-banner {
  max-width: 446px;

  &__image {
    width: 100%;
    display: block;
    margin-bottom: $indent-mmedium;
    border-radius: 2px;
    overflow: hidden;
  }

  &__title {
    @include font-style($font-size-heading, $line-height-heading, $font-weight-bold);
    margin-bottom: $indent-compact;
  }

  &__link {
    font-weight: $font-weight-semibold;
    text-decoration: underline;

    &:hover {
      text-decoration: none;
    }
  }
}
</style>
