<template>
  <div class="osk-header-search-banner">
    <div class="osk-header-search-banner__title">
      Oskelly Concierge
    </div>

    <div class="osk-header-search-banner__text">
      {{ $t('components.concierge.modal.text') }}
    </div>

    <UikitButton
      outline
      full-width
      size="s"
      class="osk-header-search-banner__button"
      @click.prevent="goToConciergePage"
    >
      {{ $t('components.concierge.modal.createRequest') }}
    </UikitButton>
  </div>
</template>

<script setup lang="ts">
const { goToConcierge, isConciergeDialogOpen } = useConcierge();

const emits = defineEmits<{
  (e: 'clickLink'): void,
}>();

async function goToConciergePage() {
  emits('clickLink');
  await goToConcierge();
  setTimeout(() => {
    isConciergeDialogOpen.value = true;
  }, 300);
}
</script>

<style scoped lang="scss">
.osk-header-search-banner {
  padding: var(--indent-medium);
  background-color: var(--grey-4);

  &__title {
    font-weight: var(--font-weight-bold);
    font-size: var(--font-size-subheading);
    margin-bottom: var(--indent-compact);
  }

  &__text {
    font-size: var(--font-size-body);
    margin-bottom: var(--indent-medium);
  }

  &__button {

  }
}
</style>
