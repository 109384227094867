<template>
  <header
    ref="$header"
    class="osk-header"
  >
    <div class="osk-wrapper osk-mobile-hidden">
      <div class="osk-header__top">
        <nuxt-link
          to="/"
          class="osk-header__logo"
        >
          <img
            src="~assets/img/oskelly-logo-new.svg"
            alt="Oskelly logo"
          >
        </nuxt-link>

        <HeaderTopActions />
      </div>

      <div class="osk-header__categories">
        <div class="osk-header__categories-links">
          <nuxt-link
            v-for="category in genderCategories"
            :key="category.id"
            :to="`/${category.name}`"
            :data-text="$t(category.text)"
            no-prefetch
            :class="{
              'osk-header__categories-link': true,
              'osk-header__categories-link_active': isActiveMenuCategory(category.name)
            }"
            @click="handleCategoryClick(category)"
          >
            {{ $t(category.text) }}
          </nuxt-link>
        </div>

        <HeaderSearchDesktopView />
      </div>
    </div>

    <HeaderBottom
      v-if="categories?.length && route.fullPath !== '/' && route.fullPath !== '/en-US'"
      class="osk-mobile-hidden"
      :categories="categories"
    />

    <HeaderMobileCountry
      v-if="CHANGE_COUNTRY_WEB_EXPERIMENT_ON"
      class="osk-desktop-hidden"
    />
    <HeaderMobileTop class="osk-desktop-hidden" />
  </header>
</template>

<script lang="ts" setup>
import { AnalyticsEvents } from '~/types/analytics';

const { $addEvent } = useNuxtApp();
const $header = ref();

const menu = useMenu();
const route = useRoute();
const { isActiveMenuCategory, activeMenuCategory } = useActiveMenuCategory();
const { genderCategories } = useGender();
const { CHANGE_COUNTRY_WEB_EXPERIMENT_ON } = useExperimentsStore();

const categories = computed(() => activeMenuCategory.value && menu[activeMenuCategory.value]);

function handleCategoryClick(category: {id: string | number, text: string}) {
  $addEvent(AnalyticsEvents.MenuLinkClick, {
    category_id: category.id,
    item: 'category',
  });
}

defineExpose({
  headerEl: $header,
});
</script>

<style scoped lang="scss">
@import "~/assets/scss/settings/index";

.osk-header {
  @include font-body-all;
  position: sticky;
  top: 0;
  width: 100%;
  background: $white;
  z-index: 10;
  transition: all linear .2s;

  &__top {
    min-height: 72px;
    padding-top: 16px;
    padding-bottom: 20px;
    display: grid;
    grid-template-columns: 1fr auto 1fr;
  }

  &__logo {
    grid-column-start: 2;
    justify-self: center;
    align-self: center;
    z-index: 2;
  }

  &__categories {
    display: flex;
    justify-content: center;
    padding-bottom: 4px;
    position: relative;
  }

  &__categories-links {
    display: flex;
    align-items: center;
    height: 36px;
  }

  &__categories-link {
    @include active-bold;
    margin: 0 23px;
  }
}
</style>
