import { BannerDTO, PrimaryPageType } from '~/restAPI/data-contracts';
import { TGenderPagesValues } from '~/types/main';
import { useApi } from '~/restAPI';
import { Home } from '~/restAPI/Home';

export type TBanners = {
  women?: BannerDTO[];
  men?: BannerDTO[];
  kids?: BannerDTO[];
  lifestyle?: BannerDTO[];
}

const bannerCategory: Record<NonNullable<TGenderPagesValues>, PrimaryPageType> = {
  women: PrimaryPageType.FEMALE,
  men: PrimaryPageType.MALE,
  kids: PrimaryPageType.CHILD,
  lifestyle: PrimaryPageType.LIFESTYLE,
};

export function useHeaderBanner(activeCategory: Ref<TGenderPagesValues>) {
  const { createApiInstance } = useApi();
  const homeApi = createApiInstance(Home);

  const banners = useState<TBanners>('headerBanner', () => ({}));

  const bannerActiveCategory = computed(() => activeCategory.value && bannerCategory[activeCategory.value]);

  const { execute: getBanner } = useAsyncData(() => {
    if (bannerActiveCategory.value) {
      return homeApi.getApiV2HomePrimaryBanners({
        category: bannerActiveCategory.value,
      }).then((res) => {
        if (activeCategory.value) {
          banners.value[activeCategory.value] = res.data.data;
        }
      });
    }

    return Promise.resolve(null);
  }, {
    immediate: false,
  });

  function getActiveBanner() {
    const bannersByCategory = activeCategory.value && banners.value[activeCategory.value];

    if (!bannersByCategory?.length) return null;

    return bannersByCategory[Math.floor(Math.random() * bannersByCategory.length)];
  }

  const unwatch = watch(() => activeCategory.value, (value) => {
    if (!value || process.server) return;

    if (banners.value.men && banners.value.women && banners.value.kids && banners.value.lifestyle) {
      unwatch();
      return;
    }

    if (!banners.value[value]) getBanner();
  }, { immediate: true });

  return {
    getActiveBanner,
  };
}
